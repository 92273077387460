import React from "react";
import "../styles/Academics.css";

function Academics() {
  return (
    <div className="academics">
      <h1>Academics</h1>
      <section className="curriculum">
        <h2>Curriculum Overview</h2>
        <p>Details about the academic programs offered.</p>
      </section>
      <section className="departments">
        <h2>Departments</h2>
        <p>Information about different academic departments.</p>
      </section>
      <section className="extracurricular">
        <h2>Extracurricular Activities</h2>
        <p>Details about extracurricular activities available.</p>
      </section>
    </div>
  );
}

export default Academics;
