import React from "react";
import "../styles/TermsOfService.css";

function TermsOfService() {
  return (
    <div className="terms-of-service">
      <h1>Terms of Service</h1>
      <p>
        Welcome to Rising Stars Public School! These Terms of Service govern
        your use of our website and services...
      </p>
      <h2>Acceptance of Terms</h2>
      <p>
        By accessing or using our website, you agree to be bound by these terms
        and conditions...
      </p>
      <h2>Modifications to Terms</h2>
      <p>We reserve the right to modify these terms at any time...</p>
      <h2>Limitation of Liability</h2>
      <p>
        We are not liable for any damages arising from your use of our website
        or services...
      </p>
      <h2>Governing Law</h2>
      <p>
        These terms and conditions are governed by and construed in accordance
        with the laws of the jurisdiction in which we operate...
      </p>
    </div>
  );
}

export default TermsOfService;
