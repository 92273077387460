import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaBars, FaTimes } from "react-icons/fa";
import "../styles/Navbar.css";
import logo from "../assets/rising-star-logo.jpg"; // Update the path as needed

function Navbar() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <nav className="navbar">
      <div className="navbar-logo">
        <img
          src={logo}
          alt="Rising Stars Public School Logo"
          className="logo-image"
        />
        Rising Stars Public School
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        {isMenuOpen ? <FaTimes /> : <FaBars />}
      </div>
      <ul className={`navbar-menu ${isMenuOpen ? "active" : ""}`}>
        <li>
          <NavLink
            to="/"
            exact
            activeClassName="active-link"
            onClick={toggleMenu}
          >
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            activeClassName="active-link"
            onClick={toggleMenu}
          >
            About Us
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/academics"
            activeClassName="active-link"
            onClick={toggleMenu}
          >
            Academics
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/admissions"
            activeClassName="active-link"
            onClick={toggleMenu}
          >
            Admissions
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/news"
            activeClassName="active-link"
            onClick={toggleMenu}
          >
            News & Events
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            activeClassName="active-link"
            onClick={toggleMenu}
          >
            Contact Us
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

export default Navbar;
