import React from "react";
import "../styles/PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy">
      <h1>Privacy Policy</h1>
      <p>
        Welcome to Rising Stars Public School! We are committed to protecting
        your privacy...
      </p>
      <h2>Information We Collect</h2>
      <p>We may collect information about you in a variety of ways...</p>
      <h2>How We Use Your Information</h2>
      <p>
        We use the information we collect to provide, operate, and maintain our
        website...
      </p>
      <h2>Security of Your Information</h2>
      <p>
        We use administrative, technical, and physical security measures to help
        protect your personal information...
      </p>
      <h2>Changes to This Privacy Policy</h2>
      <p>We may update this privacy policy from time to time...</p>
    </div>
  );
}

export default PrivacyPolicy;
